.usefulInfoContainer {
  width: 150px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 0.25rem;
}

.object-fit-cover {
border-radius: 0.25rem;
width: 100%;
height: 100%; 
object-fit: cover;
}